import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

import * as projectListStyles from "./projectList.module.scss"

export default function ProjectList() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjects(sort: { fields: orderNumber, order: ASC }) {
        edges {
          node {
            orderNumber
            title
            slug
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const state = React.useContext(GlobalStateContext)

  return (
    <div className={projectListStyles.container}>
      <ul className={projectListStyles.projects}>
        {data.allContentfulProjects.edges.map(edge => {
          return (
            <Link to={`/${edge.node.slug}`} key={edge.node.orderNumber} className={projectListStyles.project}>
              <li>
                <img
                  className={
                    state.theme === "randTheme"
                      ? projectListStyles.iconLight
                      : projectListStyles.iconDefault
                  }
                  src={edge.node.icon.file.url}
                  alt=" "
                />
                {edge.node.title}
              </li>
            </Link>
          )
        })}
      </ul>
    </div>
  )
}
