import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Logo from "../components/svg/Logo"
import ProjectList from "../components/main/ProjectList"

import * as indexStyles from "./index.module.scss"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjects(sort: { fields: orderNumber, order: ASC }) {
        edges {
          node {
            featureImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  let pictures = []

  data.allContentfulProjects.edges.forEach(edge => {
    pictures.push(edge.node.featureImage.file.url);
  })

  useEffect(() => {
    pictures.forEach(picture => {
      const img = new Image()
      img.src = picture
    })
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Makan</title>
        <meta name="description" content="Makan is an experimental design collective, established in 2016 by Steav Nissan, Darren Dharmadasa and Frank Burridge. Having diverse educational backgrounds, it was created to maintain a platform from which to consolidate respective professional experience in practice, research and teaching, post-graduation. We primarily operate through competitions to interrogate and test various spatial conditions, both individually and in collaboration with external practices."/>
      </Helmet>
      <div className={indexStyles.center}>
        <div className={indexStyles.container}>
          <img src={'./logo.png'} alt="Makan" className={indexStyles.logo} />
          <ProjectList />
        </div>
      </div>
    </Layout>
  )
}
